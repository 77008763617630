import styled from 'styled-components'

const PostContainer = styled.article`
  height: 499px;
  max-width: 317px;
  margin-top: 30px;
  margin-right: 39.5px;
  border-style: solid;
  border-color: #eee;
  border-width: 1px;
  position: relative;

  a {
    text-decoration: none;
  }

  .blog-img-container {
    height: 318px;
    width: 317px;

    .blog-img {
      height: 100%;
      width: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 179px;
    padding: 20px 20px 30px 20px;
    background-color: #eeeeee;

    .description,
    .description h3 {
      color: #000;
      font-size: 21.5px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 10px;
    }
    .date {
      font-size: 12px;
      line-height: 28px;
      font-weight: 400;
    }
  }

  @media (max-width: 1100px) {
    .blog-img-container {
      width: 125px;
      height: 125px;
    }

    margin-right: 0;
    display: flex;
    max-width: unset;
    height: 125px;
    width: 100%;

    .description-container {
      min-height: unset;
      padding: 16px 8px 8px 10px;
      max-height: 101px;
      flex: 1;

      .description {
        font-size: 1em;
        line-height: 19px;
      }
    }
  }
`

export default PostContainer
