import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'

import Layout from '../../components/common/layout'
import Container from '../../components/common/container'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import media from '../../components/css/media'

import CategoryArticles from './categoryArticles'
import CategoryArticleTitle from './categoryArticleTitle'
import PostContainer from './postContainer'
import CategoryArticle from './categoryArticle'
import Seo from '../../components/common/seo'

/**
 * Sadly sort order of categories is not coming from CDN where our blog
 * related data is hosted
 */
const categoriesOrderByCategorySlug = [
  'categoria-dicas-de-apostas',
  'categoria-futebol',
  'categoria-novidades-da-kto',
  'categoria-cassino',
  'categoria-basquete',
  'categoria-tenis',
  'categoria-outros-esportes',
  'categoria-mitada',
]

const CategoriesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const CategoryLink = styled(Link)`
  display: inline-block;
  text-decoration: none;

  > div {
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const CategoryImageContainer = styled.div`
  background: #000;
  background-position: center !important;
  background-size: cover !important;
  padding: 1em;
  min-height: 100px;
  width: 191px;
  position: relative;
  border-radius: 5px;

  > h2 {
    max-width: 85%;
    color: #fff;
    font-size: 1.25em;
    margin-bottom: 1em;
    line-height: 30px;
    font-weight: 400;
  }
`

const ReadMoreAboutCategoryLink = styled(Link)`
  color: #000;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  text-decoration: underline;

  &.hover {
    transition: 0.3s;
    color: #c36;
    text-decoration-color: #c36;
  }
`

const ReadMoreAboutCategoryLinkMobile = styled(Link)`
  color: black;
  width: 100%;
  margin-top: 20px;
  min-height: 50px;
  font-size: 20px !important;
  border-radius: 6px;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 60px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  text-decoration: none;
  line-height: 2.4;
`

const BlogContainer = styled.div`
  padding: 1.5em 0;
  overflow: hidden;
  background: #fff;
  color: #000;

  .blogs-list-container {
    max-width: 1090px;
    margin: auto;
    margin-bottom: 40px;
  }

  .description-container {
    a.description {
      font-size: 0.9em;
      line-height: 18px;
      margin-top: -7px;
    }

    h3.date {
      margin-top: -5px;
    }
  }

  ${media.tablet`
		.description-container {
	  	a.description {
	  		font-size: 1em !important;
				line-height: 19px !important;
				margin-top: 0 !important;
	  	}

	  	h3.date {
	  		margin-top: 0 !important;
	  	}
  	}
	`}
`

const BlogCategories = ({ pageContext: { blogData } }) => {
  const sortedCategories = []
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  categoriesOrderByCategorySlug.forEach((categoryOrderBySlug) => {
    const categoryByOrder = blogData.find(
      (category) => category.categorySlug === categoryOrderBySlug
    )

    if (categoryByOrder) {
      sortedCategories.push(categoryByOrder)
    }
  })

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: `seo.blog_title` })} />
      <Container>
        <BlogContainer>
          <div className='blogs-list-container'>
            {!isMobile && (
              <CategoriesContainer>
                {sortedCategories.map((blogItem, index) => (
                  <CategoryLink key={index} to={`${blogItem.categorySlug}`}>
                    <div>
                      <CategoryImageContainer
                        style={{
                          background: `url('https://cdnblog.kto.com/wp-content/uploads/${blogItem.categoryIcon}')`,
                        }}
                      >
                        <h2> {blogItem.categoryName} </h2>
                      </CategoryImageContainer>
                    </div>
                  </CategoryLink>
                ))}
              </CategoriesContainer>
            )}

            <CategoryArticles>
              {sortedCategories.map((blogItem, index) => (
                <section key={index}>
                  <CategoryArticleTitle>
                    <div>
                      <h2>{blogItem.categoryName}</h2>
                      {!isMobile && (
                        <ReadMoreAboutCategoryLink
                          to={`${blogItem.categorySlug}`}
                        >
                          {intl.formatMessage({ id: 'common.readMore' })}
                        </ReadMoreAboutCategoryLink>
                      )}
                    </div>
                  </CategoryArticleTitle>

                  <CategoryArticle>
                    {blogItem.articles.slice(0, 3).map((article, index) => (
                      <PostContainer key={index}>
                        <div className='blog-img-container'>
                          <Link to={`${blogItem.categorySlug}/${article.slug}`}>
                            <img
                              className='blog-img'
                              src={`https://cdnblog.kto.com/wp-content/uploads/${article.featured_image}`}
                              alt={`${article.title}`}
                            />
                          </Link>
                        </div>

                        <div className='description-container'>
                          <Link
                            className='description'
                            to={`${blogItem.categorySlug}/${article.slug}`}
                          >
                            <h3>
                              {article.title.length > 50
                                ? article.title.substr(0, 50) + '...'
                                : article.title}
                            </h3>
                          </Link>
                          <time
                            className='date'
                            dateTime={dayjs(article.date_created).format(
                              'YYYY-DD-MM'
                            )}
                          >
                            {dayjs(article.date_created).format('DD.MM.YYYY')}
                          </time>
                        </div>
                      </PostContainer>
                    ))}
                  </CategoryArticle>
                  {isMobile && (
                    <ReadMoreAboutCategoryLinkMobile
                      to={`${blogItem.categorySlug}`}
                    >
                      {intl.formatMessage({ id: 'common.readMore' })}
                    </ReadMoreAboutCategoryLinkMobile>
                  )}
                </section>
              ))}
            </CategoryArticles>
          </div>
        </BlogContainer>
      </Container>
    </Layout>
  )
}

export default BlogCategories
