import styled from 'styled-components'
import media from '../../components/css/media'

const CategoryArticles = styled.div`
  margin-top: 26px;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${media.tablet`
  	width: 100%;
  `};
`

export default CategoryArticles
