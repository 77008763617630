import styled from 'styled-components'
import media from '../../components/css/media'

const CategoryArticleTitle = styled.div`
  width: 95%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    > h2 {
      margin: 0;
      text-transform: uppercase;
      font-size: 50px;
      font-weight: 900;
      line-height: 60px;
    }
  }

  ${media.desktop`
		margin-top: 95px;
	`}
`

export default CategoryArticleTitle
